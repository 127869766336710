import './App.css';
import MaintenancePage from './components/MaintenancePage';

function App() {
  return (
    <div>
        <MaintenancePage />
    </div>
);
}

export default App;
