import React from 'react';
import { Layout, Typography } from 'antd';
import { WarningOutlined } from '@ant-design/icons'; // Icono de advertencia
import './MaintenancePage.css'; // Archivo CSS para estilos adicionales si es necesario
import { Helmet } from 'react-helmet';

const { Title, Paragraph } = Typography;
const { Content } = Layout;

const MaintenancePage = () => {
    return (
        <>
        <Helmet>
            <title>Kamaro CMS</title>
            <meta name="description" content="KAMARO-CMS - News Media CMS" />
            <meta name="keywords" content="kamaro, cms, news, media, news media" />
        </Helmet>
        <Layout style={{ minHeight: '100vh', backgroundColor: '#35175c' }}> {/* Fondo violeta */}
            <Content style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center', color: 'white' }}>
                <WarningOutlined style={{ fontSize: '64px', color: 'white' }} /> {/* Icono de advertencia */}
                <Title style={{ color: 'white', fontSize: '48px', fontWeight: 'bold' }}>Sitio en Construcción</Title>
                <Paragraph style={{ fontSize: '24px', color: 'white' }}>
                    Nueva información dentro de poco
                </Paragraph>
                <div style={{ borderTop: '1px solid white', width: '80%', margin: '20px 0' }} /> {/* Línea horizontal */}
                <Paragraph style={{ fontSize: '18px', color: 'white' }}>
                    Para consultas, contactanos en: <a href="mailto:tu_correo@example.com" style={{ color: 'white', textDecoration: 'underline' }}>managment@kamaro-cms.com</a>
                </Paragraph>
            </Content>
        </Layout>
        </>
    );
};

export default MaintenancePage;